import { subscribeKey } from "valtio/utils";

import { readState } from "@/__main__/app-state.mjs";
import { IS_NODE } from "@/__main__/constants.mjs";
import mainRefs from "@/__main__/refs.mjs";
import router from "@/__main__/router.mjs";
import { appURLs, GAME_ACTIVE_MAP } from "@/app/constants.mjs";
import adsRefs from "@/feature-ads/refs.mjs";
import { ContentWrapper } from "@/feature-reskin-probuilds-net/ContentWrapper.jsx";
import { HeaderWrapper } from "@/feature-reskin-probuilds-net/HeaderWrapper.jsx";
import reskinRefs from "@/feature-reskin-probuilds-net/refs.mjs";
import { CHAMPIONS } from "@/game-lol/constants/champion-classes.mjs";
import { GAME_SYMBOL_LOL } from "@/game-lol/definition-symbol.mjs";
import lolRefs from "@/game-lol/refs.mjs";
import footerRefs from "@/marketing/footer.refs.mjs";
import { catchAllRoutes } from "@/routes/constants.mjs";
import { homepageRoutes } from "@/routes/marketing.mjs";
import routes from "@/routes/routes.mjs";
import appRouteRefs from "@/util/app-route.refs.mjs";
import deepEqual from "@/util/deep-equal.mjs";
import globals from "@/util/global-whitelist.mjs";
import lazyFn from "@/util/lazy-fn.mjs";
import mapOriginalRefs, {
  forceOverwriteIterable,
} from "@/util/map-original-refs.mjs";
import pathRegExp from "@/util/path-regexp.mjs";

const original = mapOriginalRefs({
  mainRefComponents: mainRefs.components,
  appRouteRefs,
  mainRefs,
  routes,
  footerRefs,
  lolRefs,
  reskinRefs,
  appURLs,
  adsRefs,
});

const fetchStaticData = lazyFn(
  () => import("@/game-lol/fetches/lol-fetch-static-data.mjs"),
);
const fetchTeamsAndPros = lazyFn(
  () =>
    import(
      "@/feature-reskin-probuilds-net/fetches/fetch-teams-and-pros-data.mjs"
    ),
);
// const fetchHomeData = lazyFn(
//   () => import("@/feature-reskin-probuilds-net/fetches/fetch-home-data.mjs"),
// );
// const fetchGuideData = lazyFn(
//   () => import("@/feature-reskin-probuilds-net/fetches/fetch-guide-data.mjs"),
// );
// const fetchChampionsData = lazyFn(
//   () =>
//     import("@/feature-reskin-probuilds-net/fetches/fetch-champions-data.mjs"),
// );
// const fetchChampionData = lazyFn(
//   () =>
//     import("@/feature-reskin-probuilds-net/fetches/fetch-champion-data.mjs"),
// );
// const fetchRoleData = lazyFn(
//   () => import("@/feature-reskin-probuilds-net/fetches/fetch-role-data.mjs"),
// );
// const fetchTeamData = lazyFn(
//   () => import("@/feature-reskin-probuilds-net/fetches/fetch-team-data.mjs"),
// );
// const fetchProData = lazyFn(
//   () => import("@/feature-reskin-probuilds-net/fetches/fetch-pro-data.mjs"),
// );

function findRouteIndex(match) {
  return routes.findIndex((route) => {
    return deepEqual(match, route);
  });
}

const memoizedActiveGames = {};
let unsubscribe;

const probuildsRoutes = [
  {
    path: "/",
    redirect: `${appURLs.BLITZ}/lol/probuilds/history`,
    // component: "feature-reskin-probuilds-net/Home.tsx",
    // fetchData: fetchHomeData,
  },
  {
    path: "/champions",
    redirect: `${appURLs.BLITZ}/lol/champions`,
    // component: "feature-reskin-probuilds-net/Champions.tsx",
    // fetchData: fetchChampionsData,
  },
  {
    path: pathRegExp("/champions/details/:championId{/:role}?"),
    redirect: ({ parameters }) => {
      const [championId, role] = parameters;
      const champion = CHAMPIONS?.[championId]?.key || championId;
      return `${appURLs.BLITZ}/lol/champions/${champion}/build${role ? `?role=${role}` : ""}`;
    },
    // component: "feature-reskin-probuilds-net/Champion.tsx",
    // fetchData: fetchChampionData,
  },
  {
    path: pathRegExp("/role/:role"),
    redirect: ({ parameters }) => {
      const [role] = parameters;
      return `${appURLs.BLITZ}/lol/probuilds/history?role=${role}`;
    },
    // component: "feature-reskin-probuilds-net/Role.tsx",
    // fetchData: fetchRoleData,
  },
  {
    path: "/pros",
    redirect: `${appURLs.BLITZ}/lol/tierlist`,
    // component: "feature-reskin-probuilds-net/Pros.tsx",
    // fetchData: () => {},
  },
  {
    path: pathRegExp("/pros/details/:proId"),
    redirect: `${appURLs.BLITZ}/lol/probuilds/history`,
    // component: "feature-reskin-probuilds-net/Pro.tsx",
    // fetchData: fetchProData,
  },
  {
    path: "/teams",
    redirect: appURLs.BLITZ,
    // component: "feature-reskin-probuilds-net/Teams.tsx",
    // fetchData: () => {},
  },
  {
    path: pathRegExp("/teams/details/:teamId"),
    redirect: appURLs.BLITZ,
    // component: "feature-reskin-probuilds-net/Team.tsx",
    // fetchData: fetchTeamData,
  },
  {
    path: pathRegExp("/guide/show/:region/:matchId/:puuid"),
    redirect: `${appURLs.BLITZ}/lol/probuilds/history`,
    // component: "feature-reskin-probuilds-net/Guide.tsx",
    // fetchData: fetchGuideData,
  },
];
const [homeRoute, ...otherRoutes] = probuildsRoutes;

async function fetchProbuildsData() {
  await Promise.all([fetchStaticData(), fetchTeamsAndPros()]);
}

const catchAll = {
  [catchAllRoutes]: probuildsRoutes,
  fetchData: fetchProbuildsData,
};

export function setup() {
  const homepageIndex = findRouteIndex(homepageRoutes[0]);

  if (!IS_NODE) {
    if (readState.volatile.shouldHaveAds) {
      injectScript(true);
    } else {
      unsubscribe = subscribeKey(
        readState.volatile,
        "shouldHaveAds",
        injectScript,
      );
    }
  }

  const siteName = ["probuildsnet:name", "ProBuilds.net"];
  siteName[forceOverwriteIterable] = true;

  original.set({
    mainRefs: {
      DEFAULT_META_IMAGE: {
        url: `${appURLs.CDN_PLAIN}/probuildsnet/og-image.png`,
        alt: ["probuildsnet:probuildsnetLogo", "ProBuilds.net Logo"],
        width: 640,
        height: 430,
      },
      DEFAULT_SITE: siteName,
    },
    reskinRefs: {
      activeGameSet: new Set(
        Object.getOwnPropertySymbols(GAME_ACTIVE_MAP).filter(
          (symbol) => GAME_ACTIVE_MAP[symbol],
        ),
      ),
    },
    lolRefs: {
      lolChampionsPrefix: "champions",
      lolChampionPrefix: "champion",
      lolTierlistPrefix: "tierlist",
    },
    footerRefs: {
      Logo: null,
    },
    mainRefComponents: {
      HeaderWrapper,
      ContentWrapper,
    },
    appRouteRefs: {
      pageHeaderOverride: true,
    },
    routes: {
      [homepageIndex]: homeRoute,
    },
  });

  const blacklistAds = [homeRoute];

  routes.push(catchAll, ...otherRoutes);

  original.append({
    adsRefs: {
      shouldShowAds: [
        () =>
          !blacklistAds.some(({ path }) => {
            return path === router.route?.path;
          }),
      ],
    },
  });

  for (const symbol of Object.getOwnPropertySymbols(GAME_ACTIVE_MAP)) {
    memoizedActiveGames[symbol] = GAME_ACTIVE_MAP[symbol];
    if (symbol === GAME_SYMBOL_LOL) continue;
    GAME_ACTIVE_MAP[symbol] = false;
  }
}

export function teardown() {
  unsubscribe?.();
  original.restore();

  for (const route of [catchAll, ...otherRoutes]) {
    routes.splice(routes.indexOf(route), 1);
  }

  for (const symbol of Object.getOwnPropertySymbols(GAME_ACTIVE_MAP)) {
    GAME_ACTIVE_MAP[symbol] = memoizedActiveGames[symbol];
  }
}

const injectScript = (hasAdsEnabled) => {
  if (!hasAdsEnabled) return;
  const script = globals.document.createElement("script");
  script.dataset.cfasync = false;
  script.type = "text/javascript";
  script.id = "clever-core";
  script.textContent = `
    (async function (document, window) {
      if (document.visibilityState !== "visible") {
        await new Promise((resolve) => {
          document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible") resolve();
          });
        });
      }

      var a, c = document.createElement("script"), f = window.frameElement;

      c.id = "CleverCoreLoader50506";
      c.src = "https://scripts.cleverwebserver.com/3aa6ec91cddc6c724c24c73f92d21187.js";

      c.async = !0;
      c.type = "text/javascript";
      c.setAttribute("data-target", window.name || (f && f.getAttribute("id")));
      // c.setAttribute("data-callback", "put-your-callback-function-here");
      // c.setAttribute("data-callback-url-click", "put-your-click-macro-here");
      // c.setAttribute("data-callback-url-view", "put-your-view-macro-here");

      try {
          a = parent.document.getElementsByTagName("script")[0] || document.getElementsByTagName("script")[0];
      } catch (e) {
          a = !1;
      }

      a || (a = document.getElementsByTagName("head")[0] || document.getElementsByTagName("body")[0]);
      a.parentNode.insertBefore(c, a);
    })(document, window);
  `;
  globals.document.body.appendChild(script);
};
